/* eslint-disable @typescript-eslint/naming-convention */
import { Visit } from '../visits/visits.model';
import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core/core.state';
import { ProcedureVisitRecord } from '../../shared/procedures/procedures.model';

export enum ClientsRoutes {
  'form' = '/clients/client',
  'list' = '/clients/client-list'
}

export type Gender = 'm' | 'f';
export type Phototype = 'I' | 'II' | 'III' | 'IV' | 'V' | 'VI';
export interface Client {
  id: number;
  name: string;
  email: string;
  phone: string;
  cardNumber: string;
  gender: Gender;
  phototype: Phototype;
  birthDate?: Date;
}

export interface ExtendedClient extends Client {
  visits?: Visit[];
  procedures?: ProcedureVisitRecord[];
}

export interface ClientsState extends EntityState<ExtendedClient> {
  selectedClientId: Client['id'];
}

export interface State extends AppState {
  clients: ClientsState;
}
