<div class="container"
  rtl>
  <div *ngIf="(isNewProcedure$ | async)
    || (activeProcedure$ | async)
    || !((procedureList$ | async)?.length > 0);
    else procedureListTmpl">
    <oniks-procedure></oniks-procedure>
  </div>

  <ng-template #procedureListTmpl>
    <ng-container *ngIf="procedureList$ | async as procedureList">
      <div *ngIf="procedureList?.length > 0">
        <oniks-procedure-list [procedureList$]="procedureList$"
          [canAdd]="canAdd">
        </oniks-procedure-list>
      </div>
    </ng-container>

  </ng-template>

</div>
