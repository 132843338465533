import { proceduresReducer } from './procedures/store/procedure.reducer';
import { RemindersState } from './../features/reminders/reminders.model';
import { reminderReducer } from './../features/reminders/store/reminder.reducer';
import { visitReducer } from '../features/visits/store/visit.reducer';
import { VisitsState } from '../features/visits/visits.model';
import { ClientsState } from '../features/clients/clients.model';
import { clientReducer } from '../features/clients/clients.reducer';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
  getRouterSelectors,
  routerReducer,
  RouterReducerState
} from '@ngrx/router-store';

import { environment } from '../../environments/environment';

import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { AuthState } from './auth/auth.models';
import { authReducer } from './auth/auth.reducer';
import { RouterStateUrl } from './router/router.state';
import { settingsReducer } from './settings/settings.reducer';
import { SettingsState } from './settings/settings.model';
import * as fromNotification from './notifications/store/notification.reducer';
import { ProceduresState } from '../shared/procedures/procedures.model';
import { LoadingState } from './loading/loading.models';
import { loadingReducer } from './loading/loading.reducer';

export const reducers: ActionReducerMap<AppState> = {
  loading: loadingReducer,
  auth: authReducer,
  settings: settingsReducer,
  router: routerReducer,
  clients: clientReducer,
  visits: visitReducer,
  procedures: proceduresReducer,
  reminders: reminderReducer,
  [fromNotification.notificationFeatureKey]: fromNotification.reducer
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

if (!environment.production) {
  if (!environment.dev) {
    metaReducers.unshift(debug);
  }
}

export const selectLoadingState = (state: AppState) => state.loading;
export const selectAuthState = (state: AppState) => state.auth;
export const selectSettingsState = (state: AppState) => state.settings;
export const selectRouterState = (state: AppState) => state.router;

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl // select the current url`
} = getRouterSelectors(selectRouterState);

export interface AppState {
  loading: LoadingState;
  auth: AuthState;
  settings: SettingsState;
  router: RouterReducerState<RouterStateUrl>;
  clients: ClientsState;
  visits: VisitsState;
  reminders: RemindersState;
  procedures: ProceduresState;
  [fromNotification.notificationFeatureKey]: fromNotification.State;
}
