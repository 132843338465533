import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ProcedureActions from './procedure.actions';
import * as ClientsActions from '../../../features/clients/clients.actions';
import * as VisitActions from '../../../features/visits/store/visit.actions';
import {
  ProcedureRecord,
  ProceduresState
} from '../../../shared/procedures/procedures.model';

export const proceduresFeatureKey = 'procedures';

export const adapter: EntityAdapter<ProcedureRecord> =
  createEntityAdapter<ProcedureRecord>();

export const initialState: ProceduresState = adapter.getInitialState({
  newProcedure: false,
  selectedProcedureId: null,
  lastUpdate: null,
  ids: []
});

export const proceduresReducer = createReducer(
  initialState,
  on(ProcedureActions.addProcedureSuccess, (state, { procedure, visitId }) => ({
    ...state,
    entities: {
      ...state.entities,
      [procedure.id]: procedure
    },
    visitId
  })),
  on(ProcedureActions.upsertProcedureSuccess, (state, action) =>
    adapter.upsertOne(action.procedure, state)
  ),
  on(ProcedureActions.updateProcedure, (state, action) =>
    adapter.updateOne(action.procedure, state)
  ),
  on(ProcedureActions.updateProcedures, (state, action) =>
    adapter.updateMany(action.procedures, state)
  ),
  on(ProcedureActions.deleteProcedure, (state, action) =>
    adapter.removeOne(action.procedure.id, state)
  ),
  on(ProcedureActions.deleteProcedures, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ProcedureActions.clearProcedures, (state) => adapter.removeAll(state)),
  on(ClientsActions.setActiveClient, (state) => ({
    ...state
  })),
  on(ProcedureActions.setActiveProcedure, (state, { selectedProcedureId }) => ({
    ...state,
    selectedProcedureId
  })),
  on(ProcedureActions.setActiveProcedureSuccess, (state, { procedure }) => {
    const procedureId = procedure?.id;
    // If procedureId exists, update the state using adapter.upsertOne
    if (procedureId) {
      // Return the updated state from upsertOne and merge with other properties
      return adapter.upsertOne(procedure, {
        ...state,
        selectedProcedureId: procedureId
      });
    }

    // If no procedureId, return the state unchanged
    return state;
  }),
  // Handle success of setting an active visit and updating related procedures
  on(VisitActions.setActiveVisitSuccess, (state, { visit }) => {
    // Ensure that visit.procedures exists and has valid structure
    const procedures =
      visit?.procedures?.map((procedure) => ({
        ...procedure,
        visitId: visit.id // Ensure that each procedure has a visitId field if needed
      })) || [];

    // Use setAll or setMany to update the state with the new procedures
    return procedures ? adapter.setAll(procedures, state) : state;
  }),
  on(ProcedureActions.setIsNewProcedure, (state, { newProcedure }) => ({
    ...state,
    newProcedure
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
