<div class="py-2"
  fxlayout="row"
  fxLayoutAlign="space-between">
  <div class="coll">
    <h1 class="main-heading">{{ 'oniks.reminders.title' | translate }}</h1>
  </div>
</div>

<div *ngIf="dataSource?.data"
  class="mat-elevation-z8 row">
  <mat-card class="col">
    <mat-form-field>
      <mat-label>{{'oniks.common.table.filter' | translate}}</mat-label>
      <input matInput
        (keyup)="applyFilter($event)"
        placeholder="{{'oniks.common.table.filter-ex' | translate}}"
        #input>
    </mat-form-field>

    <div class="row">
      <table mat-table
        class="col"
        [dataSource]="dataSource"
        matSort>

        <!-- Name Column -->
        <ng-container matColumnDef="clientName"
          sticky>
          <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'oniks.reminders.table.column.clientName' | translate}} </th>
          <td mat-cell
            *matCellDef="let row">
            <a [routerLink]="[clientsRoute,row.client.id]">{{row.client.name}}</a>
          </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date"
          sticky>
          <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'oniks.reminders.table.column.date' | translate}} </th>
          <td mat-cell
            *matCellDef="let row">
            <a (click)="showDetails(row)">
              <button mat-flat-button>{{row.date | date:'dd.MMM.yyyy'|uppercase}}</button>
            </a>
          </td>
        </ng-container>

        <!-- Procedure Column -->
        <ng-container matColumnDef="procedureCodes"
          sticky>
          <th mat-header-cell
            fxHide
            fxShow.gt-sm
            *matHeaderCellDef
            mat-sort-header> {{'oniks.reminders.table.column.procedureCode' | translate}} </th>
          <td mat-cell
            fxHide
            fxShow.gt-sm
            *matCellDef="let row">
            {{ formatProcedureCodes(row.procedureCodes) }}
          </td>
        </ng-container>

        <!-- Actions column -->
        <ng-container matColumnDef="pk"
          stickyEnd
          class="justify-content-end">
          <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'oniks.clients-list.table.actions' | translate}}
          </th>
          <td mat-cell
            *matCellDef="let row">
            <mat-icon class="col-6 align-self-end">
              <fa-icon icon="trash"
                (click)="delete(row)"
                [matTooltip]="'oniks.common.table.tooltip.delete' | translate"
                matTooltipPosition="above">
              </fa-icon>
            </mat-icon>

        </ng-container>

        <tr mat-header-row
          *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
          *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row"
          *matNoDataRow>
          <td class="mat-cell"
            colspan="4">{{'oniks.common.table.no-data-matching-filter' | translate}}
            "{{input.value}}"
          </td>
        </tr>

      </table>
    </div>

    <div class="row">
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"
        class="col"></mat-paginator>
    </div>
  </mat-card>
</div>
