import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Client } from './clients.model';
import { ProcedureVisitRecord } from '../../shared/procedures/procedures.model';
import { VisitListItem } from '../visits/visits.model';

export const initClients = createAction('[Client/API] Init');

export const loadClients = createAction(
  '[Client/API] Load Clients',
  props<{ clients: Client[] }>()
);

export const addClient = createAction(
  '[Client/API] Add Client',
  props<{ client: Client }>()
);

export const addClientSuccess = createAction(
  '[Client/API] Add Client success',
  props<{ client: Client }>()
);

export const addClientFail = createAction(
  '[Client/API] Add Client fail',
  props<{ error: string }>()
);

export const upsertClient = createAction(
  '[Client/API] Upsert Client',
  props<{ client: Client }>()
);

export const addClients = createAction(
  '[Client/API] Add Clients',
  props<{ clients: Client[] }>()
);

export const upsertClients = createAction(
  '[Client/API] Upsert Clients',
  props<{ clients: Client[] }>()
);

export const updateClient = createAction(
  '[Client/API] Update Client',
  props<{ client: Update<Client> }>()
);

export const updateClients = createAction(
  '[Client/API] Update Clients',
  props<{ clients: Update<Client>[] }>()
);

export const deleteClient = createAction(
  '[Client/API] Delete Client',
  props<{ id: number }>()
);

export const deleteClientSuccess = createAction(
  '[Client/API] Delete Client success',
  props<{ id: number }>()
);

export const deleteClients = createAction(
  '[Client/API] Delete Clients',
  props<{ ids: string[] }>()
);

export const newVisitForClient = createAction(
  '[Client/API] New visit For Client',
  props<{ clientId?: string }>()
);

export const loadClientProcedures = createAction(
  '[Procedure/API] Load Client Procedures',
  props<{ clientId: number }>()
);

export const loadClientProceduresSuccess = createAction(
  '[Procedure/API] Load Client Procedures Success',
  props<{ clientId: number; procedures: ProcedureVisitRecord[] }>()
);

export const loadClientVisits = createAction(
  '[Visit] Load Client Visits',
  props<{ clientId: number }>()
);

export const loadClientVisitsSuccess = createAction(
  '[Visit] Load Client Visits Success',
  props<{ visits: VisitListItem[] }>()
);

export const loadClientVisitsFailure = createAction(
  '[Visit] Load Client Visits Failure',
  props<{ error: any }>()
);

export const clearClients = createAction('[Client/API] Clear Clients');
export const resetClientForm = createAction('[Client/API] Reset Client Form');
export const setActiveClient = createAction(
  '[Client/API] Set active',
  props<{ selectedClientId: Client['id'] }>()
);
