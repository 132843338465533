import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';
import { NotificationService } from '../../core/core.module';
import { TranslateService } from '@ngx-translate/core';
import {
  GroupedReminder,
  PostReminderDTO
} from '../../features/reminders/reminders.model';
import { ClientsRoutes } from '../../features/clients/clients.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'oniks-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe]
})
export class RemindersComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() reminderList$: Observable<GroupedReminder[]>;

  @Output() deleteReminderEvent = new EventEmitter<PostReminderDTO>();

  displayedColumns: (keyof GroupedReminder)[] = [
    'clientName',
    'procedureCodes',
    'date'
    // 'id'
  ];
  dataSource = new MatTableDataSource<GroupedReminder>([]);

  clientsRoute = ClientsRoutes.form;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.reminderList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((reminders) => (this.dataSource.data = reminders));
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    setTimeout(() =>
      this.sort.sort({
        id: 'date',
        start: 'desc'
      } as MatSortable)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showDetails(record: GroupedReminder): void {
    const procedures = record.procedureCodes.map((code) =>
      this.translateService.instant(`oniks.procedures.form.${code}`)
    );
    this.notificationService.confirm(procedures.join(', '));
  }

  delete(reminder: PostReminderDTO): void {
    this.notificationService
      .confirm(
        this.translateService.instant(
          'oniks.common.table.actions.confirm-delete',
          {
            record: `$ID: ${reminder.id}} - ${this.datePipe
              .transform(reminder.date, 'dd.MMM.yyyy')
              .toUpperCase()}"`
          }
        )
      )
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((result) => result),
        tap(() => this.deleteReminderEvent.emit(reminder))
      )
      .subscribe();
  }

  formatProcedureCodes(
    procedureCodes: GroupedReminder['procedureCodes']
  ): string {
    return procedureCodes
      ?.map((code) =>
        this.translateService.instant(`oniks.procedures.form.${code}`)
      )
      .join(', ');
  }
}
