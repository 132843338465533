import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import {
  PaginationInfo,
  Visit,
  VisitRoutes
} from '../../features/visits/visits.model';
import { State } from '../../features/clients/clients.reducer';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Input
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { VisitListItem } from '../../features/visits/visits.model';
import { MatTableDataSource } from '@angular/material/table';
import { Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { setActiveVisit } from '../../features/visits/store/visit.actions';
import { Client, ClientsRoutes } from '../../features/clients/clients.model';
import {
  newVisitForClient,
  setActiveClient
} from '../../features/clients/clients.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'oniks-visit-list',
  templateUrl: './visit-list.component.html',
  styleUrls: ['./visit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() dataSource: MatTableDataSource<VisitListItem>;

  @Output() paginatorInitialized = new EventEmitter<MatPaginator>();

  @Output() deleteOrderEvent = new EventEmitter<number>();

  // Notify parent about filter, pagination, and sorting changes
  @Output() filterChange = new EventEmitter<string>(); // When the filter changes
  @Output() sortChange = new EventEmitter<{
    orderBy: string;
    direction: string;
  }>(); // When sorting changes

  @Input() paginationInfo$: Observable<PaginationInfo>;

  displayedColumns: (keyof VisitListItem)[] = ['clientName', 'date', 'id'];

  clientsRoute = ClientsRoutes.form;

  addVisitRoute = VisitRoutes.form;

  private unsubscribe$ = new Subject<void>();

  constructor(private store: Store<State>, private router: Router) {}

  ngOnInit(): void {}

  editClient(selectedClientId: Client['id']): void {
    this.store.dispatch(setActiveClient({ selectedClientId }));
    this.router.navigate([ClientsRoutes.form, selectedClientId]);
  }

  edit(visit: Visit): void {
    this.store.dispatch(
      setActiveVisit({ selectedVisitId: visit.id, dispatch: true })
    );
    this.router.navigate([VisitRoutes.form, visit.id]);
  }

  newVisit() {
    this.store.dispatch(newVisitForClient({}));
  }

  ngAfterViewInit(): void {
    // Initialize the sorting mechanism
    this.sort.sortChange.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.sortChange.emit({
        orderBy: this.sort.active,
        direction: this.sort.direction || 'asc'
      });
    });

    this.paginatorInitialized.emit(this.paginator); // Let the parent know paginator is ready
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.trim();
    this.filterChange.emit(filterValue); // Emit the filter value to the parent
  }

  delete(id: number): void {
    this.deleteOrderEvent.emit(id); // Inform the parent that a deletion is requested
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
