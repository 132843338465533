import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Visit } from '../../../features/visits/visits.model';
import { ProcedureRecord } from '../../../shared/procedures/procedures.model';

export const setIsNewProcedure = createAction(
  '[Procedure/API] Set is new procedure',
  props<{ newProcedure: boolean }>()
);
export const addProcedure = createAction(
  '[Procedure/API] Add Procedure',
  props<{ procedure: ProcedureRecord; visit: Visit }>()
);

export const addProcedureSuccess = createAction(
  '[Procedure/API] Add Procedure success',
  props<{ procedure: ProcedureRecord; visitId: Visit['id'] }>()
);

export const addProcedureFail = createAction(
  '[Procedure/API] Add Procedure Fail',
  props<{ error: any }>()
);

export const upsertProcedure = createAction(
  '[Procedure/API] Upsert Procedure',
  props<{ procedure: ProcedureRecord; visit: Visit }>()
);

export const upsertProcedureSuccess = createAction(
  '[Procedure/API] Upsert Procedure success',
  props<{
    procedure: ProcedureRecord;
    visitId: Visit['id'];
  }>()
);

export const updateProcedure = createAction(
  '[Procedure/API] Update Procedure',
  props<{ procedure: Update<ProcedureRecord> }>()
);

export const updateProcedures = createAction(
  '[Procedure/API] Update Procedures',
  props<{ procedures: Update<ProcedureRecord>[] }>()
);

export const deleteProcedure = createAction(
  '[Procedure/API] Delete Procedure',
  props<{
    procedure: ProcedureRecord;
    visit?: Visit;
    triggerVisitUpsert?: boolean;
  }>()
);

export const deleteOrderProcedures = createAction(
  '[Procedure/API] Delete visit Procedures',
  props<{ procedures: ProcedureRecord[]; visit: Visit }>()
);

export const deleteProcedureSuccess = createAction(
  '[Procedure/API] Delete Procedure success'
);

export const deleteProcedureFail = createAction(
  '[Procedure/API] Delete Procedure fail'
);

export const deleteProcedures = createAction(
  '[Procedure/API] Delete Procedures',
  props<{ ids: string[] }>()
);

export const clearProcedures = createAction('[Procedure/API] Clear Procedures');
export const setActiveProcedure = createAction(
  '[Procedure/API] Set active',
  props<{ selectedProcedureId: ProcedureRecord['id'] }>()
);

export const setActiveProcedureSuccess = createAction(
  '[Procedure] Set Active Procedure Success',
  props<{ procedure: ProcedureRecord }>()
);

export const setActiveProcedureFailure = createAction(
  '[Procedure] Set Active Procedure Failure',
  props<{ error: any }>()
);

export const submitProcedure = createAction(
  '[Procedure/API] Submit Procedure',
  props<{ procedureRecord: ProcedureRecord }>()
);

export const submitProcedureSuccess = createAction(
  '[Procedure/API] Submit Procedure Success',
  props<{ procedure: ProcedureRecord }>()
);
