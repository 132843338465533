import { createReducer, on, Action } from '@ngrx/store';
import { httpRequestEnd, httpRequestStart } from './loading.actions';
import { LoadingState } from './loading.models';

export const initialState: LoadingState = {
  httpOperations: 0
};

const reducer = createReducer(
  initialState,
  on(httpRequestStart, (state) => ({
    ...state,
    httpOperations: state.httpOperations + 1
  })),
  on(httpRequestEnd, (state) => ({
    ...state,
    httpOperations: Math.max(0, state.httpOperations - 1) // Ensure we don't go below 0
  }))
);

export function loadingReducer(
  state: LoadingState | undefined,
  action: Action
): LoadingState {
  return reducer(state, action);
}
