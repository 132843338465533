import { Client } from '../clients/clients.model';
import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core/core.state';
import { ProcedureRecord } from '../../shared/procedures/procedures.model';
import { PostReminderDTO } from '../reminders/reminders.model';

export enum VisitRoutes {
  'form' = '/visits/visit',
  'visitForClient' = '/visits/client',
  'list' = '/visits/visit-list'
}

export interface Visit {
  id: number;
  clientId: Client['id'];
  date: Date;
  hour: number;
  minutes: number;
  procedures: ProcedureRecord[];
  price: number;
  comment: string;
  eventId: string;
  client?: Client;
  reminders?: PostReminderDTO[];
}

export interface VisitListItem extends Visit {
  clientName: string;
}

export interface PaginationInfo {
  page: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface VisitsState extends EntityState<Visit> {
  selectedVisitId: Visit['id'];
  paginationInfo: PaginationInfo;
}

export interface State extends AppState {
  visits: VisitsState;
}
