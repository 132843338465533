import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  switchMap,
  map,
  catchError,
  tap,
  withLatestFrom,
  filter
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ProceduresService } from '../procedures.service';
import {
  addProcedure,
  addProcedureSuccess,
  deleteProcedure,
  deleteProcedureSuccess,
  upsertProcedureSuccess,
  upsertProcedure,
  submitProcedure,
  setActiveProcedure,
  setActiveProcedureFailure,
  setActiveProcedureSuccess,
  setIsNewProcedure,
  addProcedureFail
} from './procedure.actions';
import { NotificationService } from '../../core.module';
import { getActiveVisit } from '../../../features/visits/store/visit.selectors';
import { ProcedureRecord } from '../../../shared/procedures/procedures.model';
import { selectProcedureEntities } from './procedure.selectors';
import { setActiveVisit } from '../../../features/visits/store/visit.actions';

@Injectable()
export class ProcedureEffects {
  public addProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProcedure),
      switchMap((action) => this.proceduresService.add(action.procedure)),
      map((procedure) =>
        upsertProcedureSuccess({ procedure, visitId: procedure.visit.id })
      ),
      catchError(() => {
        this.notificationService.error('Failed to add procedure.');
        return of({ type: 'ADD_PROCEDURE_FAILURE' });
      })
    )
  );

  public updateProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertProcedure),
      switchMap((action) => this.proceduresService.update(action.procedure)),
      map((procedure) =>
        upsertProcedureSuccess({ procedure, visitId: procedure.visitId })
      ),
      catchError(() => {
        this.notificationService.error('Failed to update procedure.');
        return of({ type: 'UPDATE_PROCEDURE_FAILURE' });
      })
    )
  );

  public upsertProcedureSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(upsertProcedureSuccess),
        tap(() =>
          this.store.dispatch(setActiveProcedure({ selectedProcedureId: null }))
        ),
        tap(() =>
          this.store.dispatch(setIsNewProcedure({ newProcedure: false }))
        ),
        tap((action) =>
          this.store.dispatch(
            setActiveVisit({
              selectedVisitId: action.visitId,
              refresh: true
            })
          )
        ),
        tap(() => {
          this.notificationService.info('oniks.common.form.operation-success');
        }),
        catchError(() => {
          this.notificationService.error('Failed to update procedure.');
          return of({ type: 'UPDATE_PROCEDURE_FAILURE' });
        })
      ),
    { dispatch: false }
  );

  public deleteProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProcedure),
      switchMap((action) =>
        this.proceduresService.delete(action.procedure.id).pipe(
          map(() => deleteProcedureSuccess()),
          catchError(() => {
            this.notificationService.error('Failed to delete procedure.');
            return of({ type: 'DELETE_PROCEDURE_FAILURE' });
          })
        )
      )
    )
  );

  public submitProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitProcedure),
      withLatestFrom(
        this.store.pipe(
          select(getActiveVisit),
          filter((visit) => !!visit)
        )
      ),
      switchMap(([action, visit]) => {
        if (!visit) {
          return of(addProcedureFail({ error: 'Visit missing' }));
        }

        const procedureRecord: ProcedureRecord = {
          ...action.procedureRecord,
          visitId: visit.id
        };

        // Decide whether to add or upsert the procedure based on if it's new or existing
        const isExistingProcedure = !!procedureRecord.id;

        // Return the appropriate action directly, without wrapping in `of()` inside the switchMap
        return isExistingProcedure
          ? of(upsertProcedure({ procedure: procedureRecord, visit }))
          : of(addProcedure({ procedure: procedureRecord, visit }));
      }),
      catchError((error) => of(addProcedureFail({ error })))
    )
  );

  public procedureSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addProcedureSuccess),
        tap(({ visitId }) => {
          this.notificationService.info('Procedure saved successfully');
          this.store.dispatch(
            setActiveVisit({ selectedVisitId: visitId, refresh: true })
          );
          this.store.dispatch(setIsNewProcedure({ newProcedure: false }));
        })
      ),
    { dispatch: false }
  );

  public procedureFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addProcedureFail),
        tap(({ error }) => {
          this.notificationService.error(`Failed to save procedure: ${error}`);
          this.store.dispatch(
            setActiveProcedure({ selectedProcedureId: null })
          );
          this.store.dispatch(setIsNewProcedure({ newProcedure: false }));
        })
      ),
    { dispatch: false }
  );

  public setActiveProcedure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setActiveProcedure),
      withLatestFrom(this.store.pipe(select(selectProcedureEntities))),
      switchMap(([action, procedures]) => {
        const procedureId = action.selectedProcedureId;
        const existingProcedure = procedures[procedureId] || null;

        // If the procedure exists in the store, return the success action immediately
        if (existingProcedure || !procedureId) {
          return of(
            setActiveProcedureSuccess({ procedure: existingProcedure })
          );
        }

        // Otherwise, fetch the procedure from the backend API
        return this.proceduresService.getProcedureById(procedureId).pipe(
          map((procedure) => setActiveProcedureSuccess({ procedure })),
          catchError((error) => {
            this.notificationService.error('Failed to load procedure');
            return of(setActiveProcedureFailure({ error }));
          })
        );
      })
    )
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly proceduresService: ProceduresService,
    private readonly notificationService: NotificationService,
    private readonly store: Store
  ) {}
}
