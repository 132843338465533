{
  "name": "oniks",
  "version": "1.5.0",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start:win": "set NODE_OPTIONS=--max_old_space_size=16386 && npm run ng serve --open",
    "start": "npm run ng serve --open",
    "start:api": "cd projects/api/oniks && sam local start-api",
    "start:prod": "npm run build:prod && npm run server && docker-compose.yml",
    "build": "ng build",
    "build:dev": "ng build --configuration dev",
    "build:prod": "ng build --configuration production",
    "lint": "eslint --color -c .eslintrc --ext .ts .",
    "test": "npm run lint && ng test --configuration=test",
    "watch": "ng test --configuration=test --browsers ChromeHeadless --watch --reporters dots",
    "e2e": "npm run ng e2e",
    "e2e:ci": "npm run ng e2e",
    "ci": "npm run format:test && npm run lint && ng test --configuration=test --browsers ChromeTravisCi --code-coverage && npm run build:prod -- --deploy-url /oniks/ --base-href /oniks",
    "format:write": "prettier projects/**/*.{ts,json,md,scss} --write",
    "format:test": "prettier projects/**/*.{ts,json,md,scss} --list-different",
    "release": "standard-version && git push --follow-tags origin master",
    "analyze": "npm run build:prod -- --stats-json && webpack-bundle-analyzer ./dist/oniks/stats-es2015.json",
    "server": "node ./projects/server/server.js",
    "contributors:add": "all-contributors add",
    "contributors:generate": "all-contributors generate && node .all-contributors-html.js",
    "contributors:check": "all-contributors check",
    "publish:prod": "sh ./cicd/publish-prod.sh",
    "publish:dev": "sh ./cicd/publish-dev.sh",
    "upload-sources": "aws s3 sync ./dist/oniks/ s3://oniks-prod --delete",
    "upload-sources:dev": "aws s3 sync ./dist/oniks/ s3://dev.oniks.click --delete",
    "invalidate-distribution": "aws cloudfront create-invalidation --distribution-id E2PTW6EFP4R076 --paths '/*'"
  },
  "husky": {
    "hooks": {
      "pre-commit": "pretty-quick --staged",
      "commit-msg": "commitlint -E HUSKY_GIT_PARAMS"
    }
  },
  "commitlint": {
    "extends": [
      "@commitlint/config-conventional"
    ]
  },
  "private": true,
  "dependencies": {
    "@angular-extensions/elements": "~18.2.1",
    "@angular/animations": "^18.2.5",
    "@angular/cdk": "^18.2.4",
    "@angular/common": "^18.2.5",
    "@angular/compiler": "^18.2.5",
    "@angular/core": "^18.2.5",
    "@angular/flex-layout": "^15.0.0-beta.41",
    "@angular/forms": "^18.2.5",
    "@angular/material": "^18.2.7",
    "@angular/platform-browser": "^18.2.5",
    "@angular/platform-browser-dynamic": "^18.2.5",
    "@angular/router": "^18.2.5",
    "@angular/service-worker": "^18.2.5",
    "@fortawesome/angular-fontawesome": "^0.15.0",
    "@fortawesome/fontawesome-common-types": "^6.1.0",
    "@fortawesome/fontawesome-free": "^6.1.0",
    "@fortawesome/fontawesome-svg-core": "^6.1.0",
    "@fortawesome/free-brands-svg-icons": "^6.1.0",
    "@fortawesome/free-solid-svg-icons": "^6.1.0",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/entity": "^18.0.2",
    "@ngrx/router-store": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "bootstrap": "^5.3.3",
    "browser-detect": "^0.2.28",
    "ngx-spinner": "^17.0.0",
    "rxjs": "~7.5.0",
    "tslib": "^2.0.0",
    "uuid": "^8.3.1",
    "zone.js": "~0.14.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.5",
    "@angular-eslint/eslint-plugin": "^0.6.0-beta.0",
    "@angular/cli": "^18.2.5",
    "@angular/compiler-cli": "^18.2.5",
    "@angular/language-service": "^18.2.5",
    "@commitlint/cli": "^19.5.0",
    "@commitlint/config-conventional": "^11.0.0",
    "@ngrx/schematics": "^13.2.0",
    "@types/gapi": "^0.0.41",
    "@types/jasmine": "~3.6.0",
    "@types/jasminewd2": "^2.0.8",
    "@types/node": "^18.19.5",
    "@types/uuid": "^8.3.0",
    "@typescript-eslint/eslint-plugin": "^8.6.0",
    "@typescript-eslint/parser": "^8.6.0",
    "all-contributors-cli": "^6.19.0",
    "aws-sdk": "^2.928.0",
    "codelyzer": "^6.0.2",
    "eslint": "^8.57.1",
    "eslint-config-prettier": "^6.15.0",
    "eslint-plugin-import": "^2.25.4",
    "express": "^4.21.0",
    "googleapis": "^92.0.0",
    "husky": "^4.3.0",
    "i": "^0.3.7",
    "jasmine-core": "~4.6.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "^1.5.0",
    "karma-spec-reporter": "^0.0.32",
    "npm": "^10.9.0",
    "npm-run-all": "^4.1.5",
    "prettier": "^2.1.2",
    "pretty-quick": "^3.1.0",
    "protractor": "^7.0.0",
    "rimraf": "^3.0.2",
    "standard-version": "^9.3.2",
    "ts-node": "~9.0.0",
    "typescript": "5.5",
    "webpack-bundle-analyzer": "^4.1.0"
  },
  "Transform": "AWS::Serverless-2016-10-31",
  "Resources": {
    "Function": {
      "Type": "AWS::Serverless::Function",
      "Properties": {
        "Description": {
          "Fn::Sub": [
            "Stack ${AWS::StackName} Function ${ResourceName}",
            {
              "ResourceName": "Function"
            }
          ]
        },
        "CodeUri": "src/Function",
        "Handler": "index.handler",
        "Runtime": "nodejs20.x",
        "MemorySize": 3008,
        "Timeout": 30,
        "Tracing": "Active"
      }
    },
    "FunctionLogGroup": {
      "Type": "AWS::Logs::LogGroup",
      "DeletionPolicy": "Retain",
      "Properties": {
        "LogGroupName": {
          "Fn::Sub": "/aws/lambda/${Function}"
        }
      }
    }
  }
}
