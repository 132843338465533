import { getSelectedClient } from './../../../features/clients/clients.selectors';
import { getVisitProcedures } from '../../../features/visits/store/visit.selectors';
import { createSelector } from '@ngrx/store';
import { adapter, proceduresFeatureKey } from './procedure.reducer';
import { selectRouteParams } from '../../core.state';
import {
  Procedure,
  ProcedureRecord,
  ProceduresState
} from '../../../shared/procedures/procedures.model';
import {
  procedureItems,
  zones,
  iterationCount,
  regimes,
  reminderPeriods
} from '../../../shared/procedures/procedure/procedure.nomenclatures';

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectProcedures = (state): ProceduresState =>
  state[proceduresFeatureKey];
export const selectAllProcedures = createSelector(selectProcedures, selectAll);
export const selectProcedureEntities = createSelector(
  selectProcedures,
  selectEntities
);

export const getSelectedProcedure = createSelector(
  selectProcedureEntities,
  selectRouteParams,
  (entities, params) => params && entities[params.id]
);

export const selectedLastUpdatedClientProcedures = createSelector(
  selectProcedures,
  getSelectedClient,
  (state, client) => state.lastUpdate[client.id]
);

export const getActiveProcedure = createSelector(
  selectProcedures,
  selectProcedureEntities,
  (state: ProceduresState, entities) =>
    entities && entities[state.selectedProcedureId]
);

export const getIsNewProcedure = createSelector(
  selectProcedures,
  (state: ProceduresState) => state.newProcedure
);

export const getProcedureItems = createSelector(
  selectProcedures,
  getVisitProcedures,
  getActiveProcedure,
  (
    state: ProceduresState,
    visitProcedures: ProcedureRecord[],
    activeProcedure: Procedure
  ): Omit<Procedure, 'visitId'>[] =>
    procedureItems.filter(
      (item) =>
        !visitProcedures?.find(
          (el) => el.code === item.code && el.code !== activeProcedure?.code
        )
    )
);

export const getProcedureZones = createSelector(
  selectProcedures,
  (state: ProceduresState): Zone[] => zones as unknown as Zone[]
);

export const getProcedureIterationCnt = createSelector(
  selectProcedures,
  (state: ProceduresState): number[] => iterationCount
);

export const getProcedureRegime = createSelector(
  selectProcedures,
  (state: ProceduresState): Procedure['regime'][] => regimes
);

export const getProcedureReminderPeriods = createSelector(
  selectProcedures,
  (state: ProceduresState): Procedure['nextReminder'][] => reminderPeriods
);
