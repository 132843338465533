import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { httpRequestStart, httpRequestEnd } from '../loading/loading.actions';

@Injectable()
export class LoadingHttpInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.store.dispatch(httpRequestStart());

    return next.handle(req).pipe(
      finalize(() => {
        // Always dispatch end action, whether the request is successful or not
        this.store.dispatch(httpRequestEnd());
      })
    );
  }
}
