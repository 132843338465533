import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, Observable, tap } from 'rxjs';
import {
  getActiveProcedure,
  getIsNewProcedure
} from '../../../core/procedures/store/procedure.selectors';
import {
  VisitProcedureRecord,
  ProcedureRecord,
  State
} from '../procedures.model';

@Component({
  selector: 'oniks-procedures',
  templateUrl: './procedures.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProceduresComponent implements OnInit {
  @Input() procedureList$: Observable<VisitProcedureRecord[]>;

  @Input() canAdd: boolean;

  activeProcedure$: Observable<ProcedureRecord>;

  isNewProcedure$: Observable<boolean>;

  constructor(public store: Store<State>) {}

  ngOnInit(): void {
    this.activeProcedure$ = this.store.pipe(
      select(getActiveProcedure),
      distinctUntilChanged()
    );

    this.isNewProcedure$ = this.store.pipe(
      select(getIsNewProcedure),
      distinctUntilChanged()
    );
  }
}
