import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { authGuard } from './core/auth/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'visits',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'visits',
    loadChildren: () =>
      import('./features/visits/visits.module').then((m) => m.VisitsModule),
    canActivate: [authGuard]
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./features/clients/clients.module').then((m) => m.ClientsModule),
    canActivate: [authGuard]
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./features/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [authGuard]
  },
  {
    path: 'reminders',
    loadChildren: () =>
      import('./features/reminders/reminders.module').then(
        (m) => m.RemindersModule
      ),
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: 'visits'
  }
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
