import { Visit } from './../../features/visits/visits.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BackendService } from '../backend/backend.service';
import { ProcedureRecord } from '../../shared/procedures/procedures.model';
import { Client } from '../../features/clients/clients.model';

@Injectable({
  providedIn: 'root'
})
export class ProceduresService {
  private readonly url: string;

  constructor(
    private readonly http: HttpClient,
    private readonly backendService: BackendService
  ) {
    this.url = `${this.backendService.nestApi}/procedures`;
  }

  public add(
    record: ProcedureRecord
  ): Observable<ProcedureRecord & { visit: Visit }> {
    return this.http.post<ProcedureRecord & { visit: Visit }>(
      this.url,
      record,
      {
        headers: this.backendService.headers
      }
    );
  }

  public update(procedure: ProcedureRecord): Observable<ProcedureRecord> {
    return this.http.put<ProcedureRecord>(
      `${this.url}/${procedure.id}`,
      procedure,
      {
        headers: this.backendService.headers
      }
    );
  }

  public delete(procedureId: number): Observable<void> {
    return this.http.delete<void>(`${this.url}/${procedureId}`, {
      headers: this.backendService.headers
    });
  }

  public get(clientId: Client['id']): Observable<ProcedureRecord[]> {
    return this.http.get<ProcedureRecord[]>(
      `${this.backendService.nestApi}/clients/${clientId}/procedures`,
      {
        headers: this.backendService.headers
      }
    );
  }
  public getProcedureById(id: number): Observable<ProcedureRecord> {
    return this.http.get<ProcedureRecord>(
      `${this.backendService.nestApi}/procedures/${id}`,
      {
        headers: this.backendService.headers
      }
    );
  }
}
