<div class="row py-2">
  <div class="col">
    <h1 class="main-heading">{{ 'oniks.visits.form.visit-list' | translate }}</h1>
  </div>

  <a (click)="newVisit()">
    <button class="col"
      mat-fab
      color="primary"
      aria-label="Add visit">
      <fa-icon icon="plus-circle"></fa-icon>
    </button>
  </a>
</div>


<div *ngIf="dataSource?.data"
  class="mat-elevation-z8 row">
  <mat-card class="col">
    <mat-form-field>
      <mat-label>{{'oniks.common.table.filter' | translate}}</mat-label>
      <input matInput
        (keyup)="applyFilter($event)"
        placeholder="{{'oniks.common.table.filter-ex' | translate}}"
        #input>
    </mat-form-field>

    <div class="row">
      <table mat-table
        class="col"
        [dataSource]="dataSource"
        matSort>
        <!-- Name Column -->
        <ng-container matColumnDef="clientName">
          <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'oniks.visits.table.clientName' | translate}} </th>
          <td mat-cell
            *matCellDef="let row">

            <button mat-button
              (click)="editClient(row.clientId)">
              {{row.clientName}}
            </button>
          </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'oniks.visits.table.date' | translate}}
          </th>
          <td mat-cell
            *matCellDef="let row">

            <button mat-flat-button
              (click)="edit(row)">
              {{row.date | date:'dd.MMM.yyyy'}} {{row.hour}}:{{row.minutes}}
            </button>
          </td>
        </ng-container>
        <!-- Price Column -->
        <!-- <ng-container matColumnDef="price"
            sticky>
            <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header>
              {{'oniks.visits.table.price' | translate}}
            </th>
            <td mat-cell
              *matCellDef="let row"> {{row.price}} </td>
          </ng-container> -->

        <!-- Actions column -->
        <ng-container matColumnDef="id"
          class="justify-content-end">
          <th mat-header-cell
            *matHeaderCellDef
            fxHide
            fxShow.gt-xs
            mat-sort-header> {{'oniks.clients-list.table.actions' | translate}}
          </th>
          <td mat-cell
            fxHide
            fxShow.gt-xs
            *matCellDef="let row">
            <mat-icon class="col-6 align-self-start">
              <fa-icon icon="edit"
                (click)="edit(row)"
                [matTooltip]="'oniks.common.table.tooltip.edit' | translate"
                matTooltipPosition="above">
              </fa-icon>
            </mat-icon>
            <mat-icon class="col-6 align-self-end">
              <fa-icon icon="trash"
                (click)="delete(row)"
                [matTooltip]="'oniks.common.table.tooltip.delete' | translate"
                matTooltipPosition="above">
              </fa-icon>
            </mat-icon>

        </ng-container>

        <tr mat-header-row
          *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
          *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row"
          *matNoDataRow>
          <td class="mat-cell"
            colspan="4">{{'oniks.common.table.no-data-matching-filter' | translate}}
            "{{input.value}}"
          </td>
        </tr>

      </table>
    </div>
    <div class="row">
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"
        [length]="(paginationInfo$ | async)?.totalItems"
        [pageSize]="(paginationInfo$ | async)?.pageSize"
        [pageIndex]="(paginationInfo$ | async)?.page - 1"
        class="col"></mat-paginator>
    </div>
  </mat-card>
</div>
