import {
  getSelectedClient,
  selectAllClients
} from '../../clients/clients.selectors';
import { PaginationInfo, Visit, VisitListItem } from '../visits.model';
import { Client } from '../../clients/clients.model';
import { adapter, visitsFeatureKey } from './visit.reducer';
import { State, VisitsState } from '../visits.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '../../../core/core.state';
import { VisitProcedureRecord } from '../../../shared/procedures/procedures.model';

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectVisitsState = createFeatureSelector<State, VisitsState>(
  visitsFeatureKey
);
export const selectAllVisits = createSelector(selectVisitsState, selectAll);
export const selectVisitEntities = createSelector(
  selectVisitsState,
  selectEntities
);

export const getRouterParamVisit = createSelector(
  selectVisitEntities,
  selectRouteParams,
  (entities, params) => params && entities[params.id]
);

export const selectVisitsPaginationInfo = createSelector(
  selectVisitsState,
  (state: VisitsState) => ({
    page: state.paginationInfo.page,
    pageSize: state.paginationInfo.pageSize,
    totalItems: state.paginationInfo.totalItems,
    totalPages: state.paginationInfo.totalPages,
    hasNextPage: state.paginationInfo.hasNextPage,
    hasPreviousPage: state.paginationInfo.hasPreviousPage
  })
);

export const getActiveVisit = createSelector(
  selectVisitEntities,
  selectVisitsState,
  (entities, state: VisitsState): Visit | null => {
    const selectedVisitId = state.selectedVisitId;
    return selectedVisitId ? entities[selectedVisitId] : null;
  }
);

export const selectPaginationInfo = createSelector(
  selectVisitsState,
  (state: VisitsState) => state.paginationInfo
);
export const selectPaginatedVisits = createSelector(
  selectVisitEntities,
  selectPaginationInfo,
  (
    entities,
    paginationInfo
  ): { visits: VisitListItem[]; paginationInfo: PaginationInfo } => {
    const startIndex = (paginationInfo.page - 1) * paginationInfo.pageSize;
    const endIndex = startIndex + paginationInfo.pageSize;

    return {
      visits: Object.values(entities).map(
        (visit) =>
          ({
            ...visit,
            clientName: visit.client?.name || ''
          } as VisitListItem)
      ),
      paginationInfo
    };
  }
);

export const selectClientVisits = createSelector(
  selectAllVisits,
  getSelectedClient,
  (visits: Visit[], client: Client) =>
    visits
      .filter((visit) => client && visit.clientId === client.id)
      .map((visit) => {
        const date = new Date(visit.date);
        date.setHours(visit.hour);
        date.setMinutes(visit.minutes);
        return {
          ...visit,
          id: visit.id,
          clientName: client?.name || '',
          date,
          price: 2,
          procedures: visit.procedures
        } as VisitListItem;
      })
);

export const getVisitProcedures = createSelector(
  getActiveVisit,
  (activeVisit): VisitProcedureRecord[] => {
    if (!activeVisit || !activeVisit?.procedures) {
      return [];
    }

    // Map the visit details with the corresponding procedures
    return activeVisit.procedures.map((procedure) => ({
      ...activeVisit,
      ...procedure,
      visitId: activeVisit.id
    }));
  }
);
