import { Environment } from './environment.model';

const packageJson = require('../../../../package.json');

export const environment: Environment = {
  appName: 'ONIKS',
  apiGw: {
    stageName: 'dev',
    version: 'v1',
    dynamoBaseUrl: 'https://2uonpw4dm7.execute-api.eu-central-1.amazonaws.com',
    nestBaseUrl: 'https://nd2dq9l36h.execute-api.eu-central-1.amazonaws.com'
    // apiBaseUrl: 'http://localhost:3000',
  },
  applicationBaseUrl: 'https://oniks.click',
  apiKey: 'ase0RZsCbp11dpFP0EpD64Gxtw22JTlj1peH84dr',
  production: false,
  dev: true,
  i18nPrefix: '',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress'],
    eslint: packageJson.devDependencies['eslint']
  },
  endpoints: {
    clientProcedures: 'client-procedures',
    calendar: 'calendar',
    clients: 'clients',
    reminders: 'reminders',
    visits: 'visits'
  }
};
