import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { GetReminderDto, PostReminderDTO } from '../reminders.model';

export const loadReminders = createAction('[Reminder/API] Load Reminders');

export const loadRemindersSuccess = createAction(
  '[Reminder/API] Load Reminders Success',
  props<{ reminders: GetReminderDto[] }>()
);

export const addReminder = createAction(
  '[Reminder/API] Add Reminder',
  props<{ reminder: PostReminderDTO }>()
);

export const addReminders = createAction(
  '[Reminder/API] Add Reminders',
  props<{ reminders: PostReminderDTO[] }>()
);

export const upsertReminders = createAction(
  '[Reminder/API] Upsert Reminders',
  props<{ reminders: PostReminderDTO[] }>()
);

export const updateReminder = createAction(
  '[Reminder/API] Update Reminder',
  props<{ reminder: Update<PostReminderDTO> }>()
);

export const updateReminders = createAction(
  '[Reminder/API] Update Reminders',
  props<{ reminders: Update<PostReminderDTO>[] }>()
);

export const noReminderToDelete = createAction(
  '[Reminder/API] No Reminder to Delete'
);

export const deleteReminder = createAction(
  '[Reminder/API] Delete Reminder',
  props<{ reminder: PostReminderDTO }>()
);

export const deleteReminders = createAction(
  '[Reminder/API] Delete Reminders',
  props<{ ids: string[] }>()
);

export const getReminder = createAction(
  '[Reminder/API] Get Reminder',
  props<{ id: GetReminderDto['id'][] }>()
);

export const getReminderSuccess = createAction(
  '[Reminder/API] Get Reminder',
  props<{ reminders: GetReminderDto[] }>()
);

export const clearReminders = createAction('[Reminder/API] Clear Reminders');
